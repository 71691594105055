.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*{
  font-family: "Montserrat";
  font-size: 20px;
}
@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 20px;
  }
}
.move{animation: MoveUpDown 2s linear infinite;}
@keyframes rotating {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -ms-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -webkit-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }
}
.rotateIn {
  -webkit-animation: rotating 5s linear infinite;
  -moz-animation: rotating 5s linear infinite;
  -ms-animation: rotating 5s linear infinite;
  -o-animation: rotating 5s linear infinite;
  animation: rotating 5s linear infinite;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.gradient{background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;}
.career{background: url('/public/images/yellow-bg.jpg'); background-position: top center; background-size: cover; }
.services:hover{
  span{ position: relative; transform: rotate(20deg);
  img{ transform: scale(0.9);}
  }
  h3{color: #fce765;}
  p{color:white;}
}
.footer{background: url('/public/images/footer-art.png'); background-repeat: repeat-x; background-position: bottom center;}
.navMobile{@apply flex;}
.mobileBurger{@apply hidden cursor-pointer;}
ul.servicesSet li {
  width: calc(25% - 15px);
}
@media only screen and (max-width: 1120px) {
  ul.servicesSet li {
    width: calc(50% - 15px);
  }
  /* .navMobile{@apply hidden;} */
  .mobileBurger{@apply block;}
  .navMobile {
    position: absolute;
    background: #fffffff2;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    transition: 1s;
}
.navMobile.navMobileActive{padding-top: 80px;
  padding-bottom: 20px; max-height: 100vh;}
.navMobile span {
    flex-direction: column;
}

.navMobile .font-regular.text-gray-500 {
    display: none;
}
}
@media only screen and (max-width: 767px) {
  ul.servicesSet li {
    width: calc(100%);
  }
}
